import { Typography, Container, Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { API, PATH, URL } from '../../../config/path';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useApp } from '../../../context/appContext';
import { useState, useEffect, useCallback } from 'react';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import client from '../../../modules/axios';

const LinkIcon = (props) => {
  return (
    <Link href={props.href}>
      <Box
        sx={{
          width: 'auto',
          height: {
            xs: 30,
            md: 40,
          },
        }}>
        <img
          src={props.src}
          width={'100%'}
          height={'100%'}
          alt="icon"
          style={{
            objectFit: 'contain',
          }}
        />
      </Box>
    </Link>
  );
};

const LinkMenu = (props) => {
  return (
    <Link
      component={ReactRouterLink}
      to={props.href}
      underline="hover"
      sx={{
        color: '#fff',
        whiteSpace: 'nowrap',
      }}>
      <Typography variant="body1">{props.menu}</Typography>
    </Link>
  );
};

const Heading = (props) => {
  const { title } = props;
  return (
    <Typography
      variant="h2"
      sx={{
        mb: {
          xs: 2,
          md: 3,
        },
        textAlign: {
          xs: 'center',
          md: 'left',
        },
        fontSize: {
          xs: '1.2rem',
        },
        fontWeight: '500',
      }}>
      {title}
    </Typography>
  );
};

const FooterSection = () => {
  const theme = useTheme();
  const { getImageAsset } = useApp();

  const [open, setOpen] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  const getBookingDetails = useCallback(() => {
    client
      .get(`${API.PRIVACY_POLICY}`)
      .then((res) => {
        const data = res.data.data;

        setPrivacyPolicy(data.content);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getBookingDetails();
  }, [getBookingDetails]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          position: {
            md: 'absolute',
          },
          bottom: 0,
          width: '100%',
          zIndex: 1,
        }}>
        <Container style={{ maxWidth: 'unset' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: {
                xs: 'center',
                md: 'flex-start',
              },
              gap: {
                xs: 4,
              },
              py: 6,
              color: '#fff',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
            }}>
            {/* indoaddict */}
            <Box>
              <Heading title={'INDOADDICT'} />

              <Box
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left',
                  },
                }}>
                <Typography>INDOADDICT Office</Typography>
                <Typography>CV. JOTU INDONESIA</Typography>
                <Typography>Parangtritis Street km 16,7</Typography>
                <Typography>Yogyakarta - Indonesia</Typography>

                <Box
                  sx={{
                    mt: 2,
                  }}>
                  <Typography>tours@indoaddict.com</Typography>
                  <Typography>+6282228751657</Typography>
                </Box>
              </Box>
            </Box>

            {/* page menus */}
            <Box>
              <Heading title={'Quick links'} />

              <Box
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.85,
                }}>
                <LinkMenu href={PATH.BLOGS} menu="Blogs" />
                <LinkMenu href={PATH.CONTACT_US} menu="Contact Us" />
                <LinkMenu href={PATH.ABOUT_US} menu="About Us" />
                <Link
                  component={ReactRouterLink}
                  to="#"
                  underline="hover"
                  onClick={handleOpen}
                  sx={{
                    color: '#fff',
                    whiteSpace: 'nowrap',
                  }}>
                  <Typography variant="body1">Privacy & Policy</Typography>
                </Link>
              </Box>
            </Box>

            {/* payment */}
            <Box>
              <Heading title={'Payment'} />

              <Box
                sx={{
                  display: 'flex',
                  gap: {
                    xs: 3,
                    md: 2,
                  },
                }}>
                <LinkIcon href="#" src={getImageAsset('visa')} />
                <LinkIcon href="#" src={getImageAsset('mastercard')} />
                <LinkIcon href="#" src={getImageAsset('paypal')} />
              </Box>
            </Box>

            {/* social media */}
            <Box>
              <Heading title={'Follow us on'} />

              <Box
                sx={{
                  display: 'flex',
                  gap: {
                    xs: 3,
                    md: 2,
                  },
                }}>
                <LinkIcon href={URL.TIKTOK} src={getImageAsset('tiktok')} />
                <LinkIcon href={URL.TRIPADVISOR} src={getImageAsset('tripadvisor')} />
                <LinkIcon href={URL.IG} src={getImageAsset('ig')} />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <PrivacyPolicyModal isOpen={open} handleClose={handleClose} privacyPolicy={privacyPolicy} />
    </>
  );
};

export default FooterSection;
