import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import client from '../../../../modules/axios';
import { API } from '../../../../config/path';
import { getAuthCookie } from '../../../../context/auth';
import { useApp } from '../../../../context/appContext';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import WestIcon from '@mui/icons-material/West';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';

const RefundButton = ({ currOrderId, isDisabled }) => {
  const { snackbar, setSnackbar } = useApp();

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [payloadRefund, setPayloadRefund] = useState({
    order_id: currOrderId,
    refund_reason: '',
  });
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsDisabledBtn(isDisabled);
  }, [isDisabled]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handlePrevStep = () => step > 1 && setStep(step - 1);
  const handleNextStep = () => step < 2 && setStep(step + 1);

  const handleRefund = () => {
    setIsLoading(true);
    setIsDisabledBtn(true);

    client
      .post(API.REFUND, payloadRefund, {
        headers: {
          Authorization: `Bearer ${getAuthCookie()}`,
        },
      })
      .then((res) => {
        if (res.data.error) {
          setSnackbar({
            ...snackbar,
            open: true,
            message: res.data.message,
            type: 'error',
          });

          setIsLoading(false);
          setIsDisabledBtn(false);

          return;
        }

        setSnackbar({
          ...snackbar,
          open: true,
          message: res.data.message,
          type: 'success',
        });

        window.location.reload();
      })
      .catch((err) => {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Internal server error',
          type: 'error',
        });

        setIsLoading(false);
        setIsDisabledBtn(false);
      });
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        color="primary"
        disabled={isDisabledBtn}
        loading={isLoading}
        loadingIndicator="Loading…"
        onClick={handleOpen}
        sx={
          {
            // px: 4,
          }
        }>
        Request Refund
      </LoadingButton>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll="body"
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '100%',
            width: {
              xs: '100%',
              md: '50%',
            },
          },
        }}>
        {step === 1 && (
          <>
            <Box
              sx={{
                px: {
                  xs: 1,
                  md: 1,
                },
                pt: 1,
              }}>
              <IconButton onClick={handleClose}>
                <ClearIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: {
                  xs: 3,
                  md: 4,
                },
                px: {
                  xs: 2,
                  md: 10,
                },
                pb: 4,
              }}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: {
                    xs: '1.15rem',
                    md: '1.25rem',
                  },
                }}>
                Cancelation and Refund Term & Conditions
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1.5,
                }}>
                <Box>
                  <Typography
                    variant="h3"
                    fontSize={{
                      xs: '1rem',
                    }}
                    fontWeight={600}>
                    A. Applicable Refund Reason
                  </Typography>
                  <List
                    sx={{
                      listStyleType: 'disc',
                      listStylePosition: 'inside',
                    }}>
                    <ListItemBullet>Self cancellation (change of plan)</ListItemBullet>
                    <ListItemBullet>Sickness</ListItemBullet>
                  </List>
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    fontSize={{
                      xs: '1rem',
                    }}
                    fontWeight={600}>
                    B. Refund Policy
                  </Typography>
                  <List
                    sx={{
                      listStyleType: 'disc',
                      listStylePosition: 'inside',
                    }}>
                    <ListItemBullet>
                      Cancellation made at least 24 hours before the tour will receive a 100%
                      refund.
                    </ListItemBullet>
                  </List>
                </Box>
                <Box>
                  <Typography
                    variant="h3"
                    fontSize={{
                      xs: '1rem',
                    }}
                    fontWeight={600}
                    sx={{
                      mb: {
                        xs: 1.4,
                      },
                    }}>
                    C. Refund Process Duration
                  </Typography>
                  <Typography variant="body1">
                    If approved, you will receive your refund within 90 days after refund request is
                    submitted. However, it can take shorter or longer, depending on the proccess
                    applied by Indoaddict.
                  </Typography>
                </Box>
              </Box>

              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleOutlineIcon />}
                      checked={isChecked}
                    />
                  }
                  label="I’ve read and agreed to the terms and conditions above."
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 'bold',
                    },
                  }}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
              </Box>

              <Box textAlign={'center'}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isChecked}
                  onClick={handleNextStep}
                  sx={{
                    px: 8,
                  }}>
                  Next
                </Button>
              </Box>
            </Box>
          </>
        )}

        {step === 2 && (
          <>
            <Box
              sx={{
                px: {
                  xs: 1,
                  md: 1,
                },
                pt: 1,
              }}>
              <IconButton onClick={handlePrevStep}>
                <WestIcon />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                px: {
                  xs: 2,
                  md: 3,
                },
                pb: 4,
              }}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: {
                    xs: '1.25rem',
                  },
                }}>
                Confirmation
              </Typography>

              <Box>
                <Typography variant="body1" mb={1}>
                  Cancelation Reason
                </Typography>
                <TextField
                  fullWidth
                  name="message"
                  variant="outlined"
                  type="text"
                  multiline
                  rows={4}
                  value={payloadRefund.refund_reason}
                  onChange={(e) => {
                    setPayloadRefund({
                      ...payloadRefund,
                      refund_reason: e.target.value,
                    });
                  }}
                  sx={{
                    '& .MuiInputBase-inputMultiline': {
                      p: 0,
                    },
                  }}
                />
              </Box>

              <Box textAlign={'center'}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRefund}
                  disabled={payloadRefund.refund_reason === '' ? true : false}
                  sx={{
                    px: 6,
                  }}>
                  Submit
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Dialog>
    </>
  );
};

const ListItemBullet = ({ children }) => {
  return (
    <ListItem
      sx={{
        display: 'flex',
        py: 0,
        gap: 1,
        alignItems: 'flex-start',
      }}>
      <Box
        sx={{
          mt: -0.2,
        }}>
        <CircleIcon
          sx={{
            fontSize: '0.45rem',
          }}
        />
      </Box>
      <Box>{children}</Box>
    </ListItem>
  );
};

export default RefundButton;
