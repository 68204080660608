export const API_BASE_URL = 'https://dev-be.indoaddict.com';

export const PATH = {
  HOME: '/',
  LOGIN: '/login-user',
  REGISTER: '/register-user',
  DESTINATION: '/destinations',
  ADVENTURES: '/adventures',
  BLOGS: '/blogs',
  PRODUCTS: '/products',
  CHECKOUT: '/checkout',
  ACTIVE_BOOKING: '/active-booking',
  HISTORY_BOOKING: '/booking-history',
  BOOKING_DETAILS: '/active-booking',
  BOOKING_HISTORY_DETAIL: '/booking-history',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CONTACT_US: '/contact-us',
  ABOUT_US: '/about-us',
};

export const API = {
  LOGIN: '/api/login',
  REGISTER: '/api/register',
  PAY: '/api/pay',
  PROFILE: '/api/profile',
  ACTIVE_ORDER: '/api/activeorder',
  HISTORY_ORDER: '/api/historyorder',
  CURRENT_CART: '/api/cart',
  ORDER_DETAILS: '/api/order/detail',
  INVOICE: '/api/invoice',
  TICKET: '/api/ticket',
  RESCHEDULE: '/api/reschedule',
  COUNTRIES: '/country-codes.json',
  REFUND: '/api/refund',
  APPLY_VOUCHER: '/api/applyvoucher',
  PRODUCT_DETAIL_BY_SLUG: '/api/product',
  CHECK_AVABILITY: '/api/checkavability',
  PRODUCTS: '/api/product',
  FORGOT_PASSWORD: '/api/forgot-password',
  RESET_PASSWORD: '/api/reset-password',
  AVABILITY_DETAIL: '/api/avabilitydetail',
  CONTACT_US: '/api/contactus',
  TOP_RATED: '/api/toprated',
  TOP_DESTINATION: 'api/topdestination',
  BLOGS: '/api/blog',
  CREATE_COMMENT: '/api/blog/comment/create',
  GET_COMMENT: '/api/blog/comment/detail',
  EDIT_COMMENT: '/api/blog/comment/edit',
  DELETE_COMMENT: '/api/blog/comment/delete',
  IMAGE_ASSET: '/api/image-static',
  RECOMMENDED_TOURS: '/api/recommended-tour',
  PRIVACY_POLICY: '/api/privacy-policy',
  SEO_PAGE: '/api/seo-page',
};

export const URL = {
  FB: '#',
  TIKTOK: 'https://www.tiktok.com/@indoaddict?_t=8nZzx4qq06z&_r=1',
  IG: 'https://www.instagram.com/indoaddicttours/',
  TRIPADVISOR:
    'https://www.tripadvisor.com/Attraction_Review-g14782503-d20352946-Reviews-Indoaddict-Yogyakarta_Yogyakarta_Region_Java.html',
  BLOGS: 'https://visitingjava.wordpress.com/',
};
