import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import client from '../modules/axios';
import Destinations from '../layout/Destinations';
import { API } from '../config/path';
import { useApp } from '../context/appContext';
import { Helmet } from 'react-helmet-async';

const initPageSettings = {
  page: 1,
  search: null,
  category: null,
  region: null,
  totalPage: 0,
};

const App = () => {
  const { snackbar, setSnackbar } = useApp();
  const [searchParams] = useSearchParams();
  const [products, setProducts] = useState(null);
  const [pageSettings, setPageSettings] = useState(initPageSettings);
  const [isSearch, setIsSearch] = useState(false);
  const [isGetProducts, setIsGetProducts] = useState(true);
  const [meta, setMeta] = useState(null);

  const getMeta = useCallback(() => {
    client
      .get(API.SEO_PAGE + '?page_name=destination')
      .then((response) => {
        const data = response.data.data;

        setMeta(data);
      })
      .catch((_error) => {
        // Snackbar.error(error.statusText);
      });
  }, []);

  useLayoutEffect(() => {
    getMeta();
  }, [getMeta]);

  useEffect(() => {
    if (searchParams.has('search')) {
      setPageSettings({
        ...pageSettings,
        search: searchParams.get('search'),
      });
    }
    if (searchParams.has('region')) {
      setPageSettings({
        ...pageSettings,
        region: searchParams.get('region'),
      });
    }
    setIsSearch(true);
  }, [searchParams.has('search'), searchParams.has('region')]);

  const getProducts = useCallback(() => {
    client
      .get(
        `${API.PRODUCTS}?${
          `page=${pageSettings.page}` +
          (pageSettings.category !== null ? `&c=${pageSettings.category}` : '') +
          (pageSettings.search !== null ? `&t=${pageSettings.search}` : '') +
          (pageSettings.region !== null ? `&r=${pageSettings.region}` : '')
        }`,
      )
      .then((res) => {
        if (res.data.error) {
          setProducts([]);
          setPageSettings({
            ...pageSettings,
            totalPage: 1,
          });

          return;
        }

        const data = res.data.data;

        setProducts(data);
        setPageSettings((prev) => ({
          ...prev,
          totalPage: res.data.total_page,
        }));
      })
      .catch((err) => {
        console.log('err', err);
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Something went wrong',
          type: 'error',
        });
      })
      .finally(() => {
        setIsGetProducts(false);
      });
  }, [pageSettings.search, pageSettings.category, pageSettings.region, pageSettings.page]);

  useEffect(() => {
    if (isSearch) {
      setPageSettings({
        ...pageSettings,
        page: 1,
      });
      getProducts();
      setIsSearch(false);
    }
  }, [isSearch, getProducts]);

  useEffect(() => {
    getProducts();
    window.scrollTo(0, 0);
  }, [pageSettings.page]);

  // console.log('products', products);

  return (
    <>
      {meta && (
        <Helmet>
          <title>{meta.title ?? 'Indoaddict'}</title>
          <meta name="keyword" content={meta.keyword} />
          <meta name="description" content={meta.description} />
        </Helmet>
      )}

      <Destinations
        products={products}
        pageSettings={pageSettings}
        setPageSettings={setPageSettings}
        setIsSearch={setIsSearch}
        isGetProducts={isGetProducts}
      />
    </>
  );
};

export default App;
