import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuthCookie } from '../context/auth';
import client from '../modules/axios';
import Product from '../layout/Product';
import { useApp } from '../context/appContext';
import { API, PATH } from '../config/path';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';

const App = () => {
  const navigate = useNavigate();
  const { snackbar, setSnackbar } = useApp();
  const [product, setProduct] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const token = getAuthCookie();

  const { slug } = useParams();

  const retrieveProduct = useCallback(() => {
    client
      .get(`${API.PRODUCT_DETAIL_BY_SLUG}/${slug}`)
      .then((response) => {
        if (response.data.error) {
          setIsNotFound(true);
          return;
        }

        const data = response.data.data[0];

        setProduct(data);
      })
      .catch(() => {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Error occured while fetching data',
          type: 'error',
        });
      });
  }, []);

  useEffect(() => {
    retrieveProduct();
  }, [retrieveProduct]);

  const handleCheckAvailibility = (date, travelers) => {
    client
      .post(
        API.CHECK_AVABILITY,
        {
          id_product: product.id,
          booking_date: date ? date : dayjs().add(1, 'day').format('YYYY-MM-DD'),
          adult: travelers.adult,
          child: travelers.child,
          infant: travelers.infant,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      )
      .then((res) => {
        if (res.data?.message) {
          if (
            res.data.message ===
            'Please complete the previous transaction before booking another tour'
          ) {
            setSnackbar({
              ...snackbar,
              open: true,
              message: res.data.message,
              type: 'error',
            });
            navigate(PATH.CHECKOUT);
            return;
          }

          setSnackbar({
            ...snackbar,
            open: true,
            message: res.data.message,
            type: 'error',
          });
          return;
        }

        const currCheckout = res.data.data;
        currCheckout.product.thumbnail = product.product_image;

        // save to local storage
        localStorage.setItem('checkout', JSON.stringify(currCheckout));
        navigate(PATH.CHECKOUT);
      })
      .catch((err) => {
        let error = err.response.data;
        let firstError = Object.keys(error)[0];

        if (error.error === 'Unauthenticated.') {
          navigate(PATH.LOGIN);
          return;
        }

        if (firstError) {
          setSnackbar({
            ...snackbar,
            open: true,
            message: error[firstError][0],
            type: 'error',
          });
        }
      });
  };

  // console.log('product', product);

  return (
    <>
      {isNotFound && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh',
            textAlign: 'center',
          }}>
          <Box>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontSize: '2rem',
                fontWeight: 600,
              }}>
              404
            </Typography>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontSize: '2rem',
                fontWeight: 600,
              }}>
              Product not found
            </Typography>
          </Box>
        </Box>
      )}

      {!isNotFound && product && (
        <>
          {product.meta_tag && (
            <Helmet>
              <title>{product.title}</title>

              {product.meta_tag.map((meta, i) => {
                if (meta.value === 'title') {
                  return <title key={i}>{meta.content}</title>;
                }

                if (meta.value === 'canonical') {
                  return <link key={i} rel="canonical" href={meta.content} />;
                }

                return <meta key={i} {...{ [meta.tag_type]: meta.value }} content={meta.content} />;
              })}
            </Helmet>
          )}
          <Product product={product} handleCheckAvailibility={handleCheckAvailibility} />
        </>
      )}
    </>
  );
};

export default App;
