import React from 'react';
import BlogDetail from '../layout/BlogDetail';

const App = () => {
  return (
    <>
      <BlogDetail />
    </>
  );
};

export default App;
