import { Grid, Pagination, Box, Container, Typography } from '@mui/material';
import { ProductCard } from '../../../../components/Card';
import { grey } from '@mui/material/colors';

const Paginate = ({ products, ...props }) => {
  if (!products) {
    return (
      <Container>
        <Box
          sx={{
            pt: 10,
          }}>
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              fontWeight: 500,
              fontSize: {
                xs: 16,
                sm: 18,
              },
              color: grey[500],
            }}>
            {(() => {
              if (props.isGetProducts) {
                return 'Retrieving data...';
              }

              if (!props.pageSettings.search) {
                return 'No result found';
              }

              return `No result found for "${props.pageSettings.search}"`;
            })()}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: {
            xs: 2,
            sm: 4,
          },
        }}>
        {products &&
          products.map(
            ({
              id,
              title,
              slug,
              duration,
              start_price,
              desc,
              include_facility,
              product_thumbnail,
              product_thumbnail_title,
            }) => (
              <Grid sx={{ width: '100%' }} item key={id}>
                <ProductCard
                  key={id}
                  id={id}
                  title={title}
                  slug={slug}
                  duration={duration}
                  start_price={start_price}
                  desc={desc}
                  include_facility={include_facility}
                  product_thumbnail={product_thumbnail}
                  product_thumbnail_title={product_thumbnail_title}
                />
              </Grid>
            ),
          )}
        <Grid item xs>
          <Pagination
            sx={[{ '.MuiPagination-ul': { justifyContent: 'center' } }]}
            count={props.pageSettings.totalPage}
            page={props.pageSettings.page}
            onChange={(e) => {
              if (e.target.textContent === '' && e.target.dataset.testid === 'NavigateBeforeIcon') {
                return props.setPageSettings({
                  ...props.pageSettings,
                  page: props.pageSettings.page - 1,
                });
              }
              if (e.target.textContent === '' && e.target.dataset.testid === 'NavigateNextIcon') {
                return props.setPageSettings({
                  ...props.pageSettings,
                  page: props.pageSettings.page + 1,
                });
              }
              props.setPageSettings({
                ...props.pageSettings,
                page: +e.target.textContent,
              });
            }}
            size="large"
            shape="rounded"
          />
        </Grid>
      </Box>
    </Container>
  );
};

export default Paginate;
