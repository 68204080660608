import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, SwipeableDrawer, List, ListItem, Divider, Link, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getAuthCookie, destroyAuthCookie, destroyAuthUser } from '../../../context/auth';
import { API, PATH } from '../../../config/path';
import { useApp } from '../../../context/appContext';
import client from '../../../modules/axios';
import { Link as ReactRouterLink } from 'react-router-dom';

const MainMenuButton = (props) => {
  const theme = useTheme();

  return (
    <Link
      component={ReactRouterLink}
      to={props.href}
      underline="none"
      color={'inherit'}
      sx={{
        '&:hover': {
          color: theme.palette.secondary.main,
        },
      }}
      {...props}>
      {props.children}
    </Link>
  );
};

const MenuButton = (props) => {
  return (
    <MenuItem onClick={props.handleClose}>
      <Button
        to={props.href}
        variant="link"
        LinkComponent={ReactRouterLink}
        sx={{
          justifyContent: 'flex-start',
        }}>
        {props.children}
      </Button>
    </MenuItem>
  );
};

const ResponsiveAppBar = () => {
  const { snackbar, setSnackbar, getImageAsset } = useApp();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profile, setProfile] = useState(null);

  const open = Boolean(anchorEl);

  const handleLogout = () => {
    destroyAuthCookie();
    destroyAuthUser();

    window.location.href = '/';

    setSnackbar({
      ...snackbar,
      open: true,
      message: 'Logout successfully',
      type: 'success',
    });
  };

  const getProfile = useCallback(() => {
    client
      .get(API.PROFILE, {
        headers: {
          Authorization: 'Bearer ' + getAuthCookie(),
        },
      })
      .then((res) => {
        setProfile(res.data.success);
      })
      .catch((err) => {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Internal server error when get profile',
          type: 'error',
        });
      });
  }, [setSnackbar, snackbar]);

  useEffect(() => {
    if (getAuthCookie()) {
      getProfile();
    }
  }, [getProfile]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      color="inherit"
      elevation={3}
      sx={{
        position: 'sticky',
        boxShadow: '0 2px 5px rgba(55,73,87,.15)',
      }}>
      <Container style={{ maxWidth: 'unset' }}>
        <Toolbar disableGutters>
          <Box
            sx={{
              py: {
                xs: 2,
              },
            }}>
            <Link
              to={PATH.HOME}
              component={ReactRouterLink}
              underline="none"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Box
                sx={{
                  width: {
                    xs: 140,
                    md: 'auto',
                  },
                  height: 30,
                }}>
                <img
                  src={getImageAsset('logo-indoaddict')}
                  alt="Logo"
                  loading="lazy"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    objectFit: 'contain',
                  }}
                />
              </Box>
            </Link>
          </Box>

          {/* mobile menus */}
          <Grid sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }} justifyContent="end">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <SwipeableDrawer
              anchor="left"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              onOpen={handleOpenNavMenu}>
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={handleOpenNavMenu}
                onKeyDown={handleOpenNavMenu}>
                <Box
                  sx={{
                    pl: 2,
                    pt: 2,
                    pb: 1,
                  }}>
                  <Link href={PATH.HOME} underline="none">
                    <Box
                      sx={{
                        width: {
                          xs: 140,
                          md: 'auto',
                        },
                        height: 30,
                      }}>
                      <img
                        src={getImageAsset('logo-indoaddict')}
                        alt="Logo"
                        loading="lazy"
                        width={'100%'}
                        height={'100%'}
                        style={{
                          objectFit: 'contain',
                        }}
                      />
                    </Box>
                  </Link>
                </Box>

                <Divider />

                <List>
                  <ListItem>
                    <MainMenuButton href={PATH.HOME}>Home</MainMenuButton>
                  </ListItem>
                  <ListItem>
                    <MainMenuButton href={PATH.DESTINATION}>Destinations</MainMenuButton>
                  </ListItem>
                  <ListItem>
                    <MainMenuButton href={PATH.CONTACT_US}>Contact Us</MainMenuButton>
                  </ListItem>
                  <ListItem>
                    <MainMenuButton href={PATH.BLOGS}>Blogs</MainMenuButton>
                  </ListItem>

                  {!getAuthCookie() && (
                    <>
                      <ListItem>
                        <MainMenuButton href={PATH.LOGIN}>Login</MainMenuButton>
                      </ListItem>
                      <ListItem>
                        <MainMenuButton href={PATH.REGISTER}>Register</MainMenuButton>
                      </ListItem>
                    </>
                  )}
                  {getAuthCookie() && (
                    <>
                      <ListItem>
                        <MainMenuButton href={PATH.ACTIVE_BOOKING}>Active Booking</MainMenuButton>
                      </ListItem>
                      <ListItem>
                        <MainMenuButton href={PATH.HISTORY_BOOKING}>Booking History</MainMenuButton>
                      </ListItem>
                      <ListItem>
                        <MainMenuButton onClick={handleLogout}>Logout</MainMenuButton>
                      </ListItem>
                    </>
                  )}
                </List>
              </Box>
            </SwipeableDrawer>
          </Grid>
          {/* end mobile menus */}

          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
              },
              gap: 6,
              flexGrow: 1,
              justifyContent: 'end',
              alignItems: 'center',
            }}>
            <MainMenuButton href={PATH.HOME}>Home</MainMenuButton>
            <MainMenuButton href={PATH.DESTINATION}>Destinations</MainMenuButton>
            <MainMenuButton href={PATH.CONTACT_US}>Contact Us</MainMenuButton>
            <MainMenuButton href={PATH.BLOGS}>Blogs</MainMenuButton>

            <Button
              variant="link"
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon />}>
              {(() => {
                if (!profile) return 'Account';
                return profile.name;
              })()}
            </Button>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            sx={{
              display: { xs: 'block' },
            }}>
            {!getAuthCookie() && (
              <Box>
                <MenuButton handleClose={handleClose} href={PATH.LOGIN}>
                  Login
                </MenuButton>
                <MenuButton handleClose={handleClose} href={PATH.REGISTER}>
                  Register
                </MenuButton>
              </Box>
            )}
            {getAuthCookie() && (
              <Box>
                <MenuButton handleClose={handleClose} href={PATH.ACTIVE_BOOKING}>
                  Active Booking
                </MenuButton>
                <MenuButton handleClose={handleClose} href={PATH.HISTORY_BOOKING}>
                  Booking History
                </MenuButton>
                <MenuButton handleClose={handleLogout}>Logout</MenuButton>
              </Box>
            )}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
