import React from 'react';
import Checkout from '../layout/Checkout';

const App = () => {
  return (
    <>
      <Checkout />
    </>
  );
};

export default App;
