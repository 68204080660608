import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const BookingCard = (props) => {
  const { order } = props;

  return (
    <>
      <Box
        sx={{
          backgroundColor: grey[200],
          borderRadius: 2,
          p: 2,
          pb: 10,
          minHeight: 500,
        }}>
        <Grid
          container
          columnSpacing={{
            sm: 2,
          }}
          rowSpacing={{
            xs: 2,
          }}
          mb={{
            xs: 2,
            sm: 4,
          }}>
          <Grid item xs={12} sm={3}>
            <Typography variant="body1" color={grey[700]}>
              Booking ID :
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              {order?.booking_id}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body1" color={grey[700]}>
              Booking Date :
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              {order?.order_date}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body1" color={grey[700]}>
              Booking By :
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              {order?.booking_by}
            </Typography>
          </Grid>
        </Grid>

        {/* package detail and purchase detail */}
        <Grid
          container
          columnSpacing={{
            sm: 2,
          }}
          rowSpacing={{
            xs: 4,
          }}
          mb={{
            xs: 4,
            sm: 2,
          }}>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="body1" mb={2} fontWeight={600}>
                Package Details
              </Typography>

              <Grid
                container
                spacing={{
                  xs: 2,
                  sm: 4,
                }}>
                <Grid item xs={4} sm={6}>
                  <Box
                    component={'img'}
                    src={order?.product_thumbnail}
                    alt={order?.product_thumbnail_title ?? order?.product_name}
                    sx={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: 1,
                      backgroundColor: 'grey.300',
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 1,
                    }}>
                    {order?.product_name}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 0.5,
                        alignItems: 'center',
                        color: grey[700],
                        backgroundColor: '#fff',
                        px: 0.5,
                      }}>
                      <LocationOnIcon
                        sx={{
                          fontSize: '1.25rem',
                        }}
                      />
                      <Typography variant="body1">{order?.city_name}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="body1" mb={2} fontWeight={600}>
                Purchase Detail
              </Typography>

              <Grid item xs={12} sm={8}>
                <table
                  style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                  }}>
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <Typography variant="body1" color={grey[700]}>
                          Payment Method
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body1" fontWeight={600} textAlign={'end'}>
                          {order?.payment_method ? order?.payment_method : '-'}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <Typography variant="body1" color={grey[700]}>
                          Price Detail
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="body1" fontWeight={600} textAlign={'end'}>
                          ${order?.order_detail.total_price}
                        </Typography>
                      </td>
                    </tr>
                    {order?.order_detail.total_adult > 0 && (
                      <tr>
                        <td style={{ paddingLeft: 6 }}></td>
                        <td colSpan={2}>
                          <Typography variant="body1" color={grey[700]}>
                            Adult ({order?.order_detail.total_adult}x)
                          </Typography>
                        </td>
                        <td>
                          <Typography variant="body1" fontWeight={600} textAlign={'end'}>
                            ${order?.order_detail.adult_price}
                          </Typography>
                        </td>
                      </tr>
                    )}
                    {order?.order_detail.total_child > 0 && (
                      <tr>
                        <td style={{ paddingLeft: 6 }}></td>
                        <td colSpan={2}>
                          <Typography variant="body1" color={grey[700]}>
                            Child ({order?.order_detail.total_child}x)
                          </Typography>
                        </td>
                        <td>
                          <Typography variant="body1" fontWeight={600} textAlign={'end'}>
                            ${order?.order_detail.child_price}
                          </Typography>
                        </td>
                      </tr>
                    )}
                    {order?.order_detail.total_infant > 0 && (
                      <tr>
                        <td style={{ paddingLeft: 6 }}></td>
                        <td colSpan={2}>
                          <Typography variant="body1" color={grey[700]}>
                            Infant ({order?.order_detail.total_infant}x)
                          </Typography>
                        </td>
                        <td>
                          <Typography variant="body1" fontWeight={600} textAlign={'end'}>
                            ${order?.order_detail.infant_price}
                          </Typography>
                        </td>
                      </tr>
                    )}
                    {order?.order_detail.discount > 0 && (
                      <tr>
                        <td style={{ paddingLeft: 6 }}></td>
                        <td>
                          <Typography variant="body1" color={grey[700]}>
                            Discount
                          </Typography>
                        </td>
                        <td></td>
                        <td>
                          <Typography variant="body1" fontWeight={600} textAlign={'end'}>
                            {order?.order_detail.discount}%
                          </Typography>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {/* end of package detail and purchase detail */}

        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: {
                xs: 1,
              },
            }}>
            <Grid
              container
              spacing={{
                xs: 2,
                sm: 4,
              }}>
              <Grid item xs={4} sm={3}>
                <Typography variant="body1" color={grey[700]}>
                  Start Date
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">{order?.start_date}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={{
                xs: 2,
                sm: 4,
              }}>
              <Grid item xs={4} sm={3}>
                <Typography variant="body1" color={grey[700]}>
                  End Date
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">{order?.end_date}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={{
                xs: 2,
                sm: 4,
              }}>
              <Grid item xs={4} sm={3}>
                <Typography variant="body1" color={grey[700]}>
                  Total Visitor
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">{order?.total_visitor}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={{
                xs: 2,
                sm: 4,
              }}>
              <Grid item xs={4} sm={3}>
                <Typography variant="body1" color={grey[700]}>
                  Departure Point
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">{order?.departure_point}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={{
                xs: 2,
                sm: 4,
              }}>
              <Grid item xs={4} sm={3}>
                <Typography variant="body1" color={grey[700]}>
                  Special Request
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">{order?.special_req}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={{
                xs: 2,
                sm: 4,
              }}>
              <Grid item xs={4} sm={3}>
                <Typography variant="body1" color={grey[700]}>
                  Traveler (*primary)
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="body1">{order?.primary_traveler}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookingCard;
