import { Box, Dialog, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ReactHtmlParser from 'html-react-parser';

const PrivacyPolicyModal = (props) => {
  const { isOpen, handleClose, privacyPolicy } = props;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll="body"
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '100%',
            width: {
              xs: '100%',
              md: '50%',
            },
          },
        }}>
        {/* CLOSE BUTTON */}
        <Box
          sx={{
            px: {
              xs: 1,
              md: 1,
            },
            pt: 1,
          }}>
          <IconButton onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </Box>
        {/* END OF CLOSE BUTTON */}

        {/* CONTENT */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: {
              xs: 3,
              md: 3,
            },
            px: {
              xs: 2,
              md: 6,
            },
            pb: 4,
          }}>
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              fontSize: {
                xs: '1.15rem',
                md: '1.25rem',
              },
            }}>
            Privacy & Policy
          </Typography>

          <Box>
            <Typography variant="body1">{ReactHtmlParser(privacyPolicy + '')}</Typography>
          </Box>
        </Box>
        {/* END OF CONTENT */}
      </Dialog>
    </>
  );
};

export default PrivacyPolicyModal;
