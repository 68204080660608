import React, { useCallback, useLayoutEffect, useState } from 'react';
import Blogs from '../layout/Blogs';
import client from '../modules/axios';
import { API } from '../config/path';
import { Helmet } from 'react-helmet-async';

const App = () => {
  const [meta, setMeta] = useState(null);

  const getMeta = useCallback(() => {
    client
      .get(API.SEO_PAGE + '?page_name=blog')
      .then((response) => {
        const data = response.data.data;

        setMeta(data);
      })
      .catch((_error) => {
        // Snackbar.error(error.statusText);
      });
  }, []);

  useLayoutEffect(() => {
    getMeta();
  }, [getMeta]);

  return (
    <>
      {meta && (
        <Helmet>
          <title>{meta.title ?? 'Indoaddict'}</title>
          <meta name="keyword" content={meta.keyword} />
          <meta name="description" content={meta.description} />
        </Helmet>
      )}
      <Blogs />
    </>
  );
};

export default App;
