import React, { useState, useLayoutEffect, useCallback } from 'react';
import client from '../modules/axios';
import Index from '../layout/Index';
import { API } from '../config/path';
import { Helmet } from 'react-helmet-async';

const App = () => {
  const [topDestinations, setTopDestinations] = useState([]);
  const [topRated, setTopRated] = useState([]);
  const [recommendedTours, setRecommendedTours] = useState([]);
  const [meta, setMeta] = useState(null);
  const [topBlogs, setTopBlogs] = useState([]);

  const getMeta = useCallback(() => {
    client
      .get(API.SEO_PAGE + '?page_name=home')
      .then((response) => {
        const data = response.data.data;

        setMeta(data);
      })
      .catch((_error) => {
        // Snackbar.error(error.statusText);
      });
  }, []);

  const getTopDestinations = useCallback(() => {
    client
      .get(API.TOP_DESTINATION)
      .then((response) => {
        const data = response.data.success;

        setTopDestinations(data);
        // Snackbar.success(response.statusText);
      })
      .catch((_error) => {
        // Snackbar.error(error.statusText);
      });
  }, []);

  const getTopRatedTours = useCallback(() => {
    client
      .get(API.TOP_RATED)
      .then((response) => {
        const data = response.data.success;

        setTopRated(data);
        // Snackbar.success(response.statusText);
      })
      .catch((_error) => {
        // Snackbar.error(error.statusText);
      });
  }, []);

  const getRecommendedTours = useCallback(() => {
    client
      .get(API.RECOMMENDED_TOURS)
      .then((response) => {
        const data = response.data.success;

        setRecommendedTours(data);
      })
      .catch((_error) => {
        // Snackbar.error(error.statusText);
      });
  }, []);

  const getTopBlogs = useCallback(() => {
    client
      .get(`${API.BLOGS}?is_top_article=true`)
      .then((response) => {
        const data = response.data.data;
        // console.log('data', data);

        setTopBlogs(data);
      })
      .catch((_error) => {
        // Snackbar.error(error.statusText);
      });
  }, []);

  useLayoutEffect(() => {
    getTopDestinations();
    getTopRatedTours();
    getRecommendedTours();
    getMeta();
    getTopBlogs();
  }, [getTopDestinations, getTopRatedTours, getRecommendedTours, getMeta, getTopBlogs]);

  // console.log('topDestinations', topDestinations);
  // console.log('topRated', topRated);

  return (
    <div>
      {meta && (
        <Helmet>
          <title>{meta.title ?? 'Indoaddict'}</title>
          <meta name="keyword" content={meta.keyword} />
          <meta name="description" content={meta.description} />
        </Helmet>
      )}
      <Index
        topRatedTours={topRated}
        topDestinations={topDestinations}
        recommendedTours={recommendedTours}
        topBlogs={topBlogs}
      />
    </div>
  );
};

export default App;
