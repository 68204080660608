import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Hr from '../../../../components/Hr';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Expect = (props) => {
  const { product } = props;

  const [isMultiDay, setIsMultiDay] = useState(false);
  const [expectations, setExpectations] = useState(null);

  const checkIsMultiDay = () => {
    if (product.what_to_expect.length === 1) {
      setExpectations(product.what_to_expect[0].activities);
      setIsMultiDay(false);

      return;
    }

    setExpectations(product.what_to_expect);
    setIsMultiDay(true);
  };

  useEffect(() => {
    if (product?.id) {
      checkIsMultiDay();
      // const { category } = product;
      // const isMultiDay = category === 'Multi tour';
      // setIsMultiDay(isMultiDay);
      // setExpectations(isMultiDay ? product.what_to_expect : product.what_to_expect[0].activities);
    }
  }, [product]);

  // console.log('expectations', expectations);

  return (
    <Container>
      <Box
        sx={{
          my: 4,
        }}>
        <Typography variant="h2" mb={3}>
          What to Expect
        </Typography>

        {/* what tp expect for not multi day */}
        {!isMultiDay && expectations && (
          <Box>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}>
              {expectations.map(function (item, i) {
                return (
                  <TimelineItem key={i}>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          bgcolor: 'black',
                          color: 'white',
                          height: '1rem',
                          width: '1rem',
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                          }}>
                          <Typography variant="h6" component="span">
                            {i + 1}
                          </Typography>
                        </Box>
                      </TimelineDot>
                      {i !== expectations.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        mb: 2,
                      }}>
                      <Typography variant="h4" fontWeight={600} mb={1}>
                        {item.activity}
                      </Typography>
                      <Typography variant="body1">{item.desc}</Typography>
                      <Typography variant="body2" color={grey[700]}>
                        {item.duration}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Box>
        )}
        {/* end of what tp expect for not multi day */}

        {/* what tp expect for multi day */}
        {isMultiDay && expectations && (
          <Box>
            {expectations.map(function (day, i) {
              return (
                <Accordion
                  key={i}
                  style={{ boxShadow: 'none' }}
                  defaultExpanded={i === 0 ? true : false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        py: 2,
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                        }}>
                        <Box
                          sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            borderRadius: 1,
                            px: 1.5,
                            py: 0.5,
                          }}>
                          <Typography variant="body1">Day {day.day}</Typography>
                        </Box>
                      </Box>
                      <Typography variant="body1">{day.activities.length} stop(s)</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      py: 0,
                    }}>
                    <Box>
                      <Timeline
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                          },
                        }}>
                        {day.activities.map(function (item, i) {
                          return (
                            <TimelineItem key={i}>
                              <TimelineSeparator>
                                <TimelineDot
                                  sx={{
                                    bgcolor: 'black',
                                    color: 'white',
                                    height: '1rem',
                                    width: '1rem',
                                  }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      width: '100%',
                                      height: '100%',
                                    }}>
                                    <Typography variant="h6" component="span">
                                      {i + 1}
                                    </Typography>
                                  </Box>
                                </TimelineDot>
                                {i !== day.activities.length - 1 && <TimelineConnector />}
                              </TimelineSeparator>
                              <TimelineContent
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 1,
                                  mb: 2,
                                }}>
                                <Typography variant="h4" fontWeight={600} mb={1}>
                                  {item.activity}
                                </Typography>
                                <Typography variant="body1">{item.desc}</Typography>
                                <Typography variant="body2" color={grey[700]}>
                                  {item.duration}
                                </Typography>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        })}
                      </Timeline>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        )}
        {/* what tp expect for multi day */}
      </Box>
      <Hr />
    </Container>
  );
};

export default Expect;
