import { useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  InputAdornment,
  Button,
  TextField,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import { grey } from '@mui/material/colors';
import { useApp } from '../../../../context/appContext';
import { PATH } from '../../../../config/path';

const App = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const { getImageAsset } = useApp();

  const [search, setSearch] = useState();

  const navigate = useNavigate();

  const handleSearch = () => {
    if (!search) {
      return;
    }
    navigate({
      pathname: PATH.DESTINATION,
      search: `?${createSearchParams({ search: search })}`,
    });
  };

  const getHeroImage = () => {
    if (mobileView) return getImageAsset('hero-mobile');

    if (tabletView) return getImageAsset('hero-tablet');

    return getImageAsset('hero');
  };

  // console.log('mobileView', mobileView);
  // console.log('tabletView', tabletView);

  return (
    <Box
      sx={{
        bgcolor: grey[100],
        height: {
          xs: '70vh',
        },
        position: 'relative',
      }}>
      <Box
        component={'img'}
        alt="Hero image"
        loading="lazy"
        src={getHeroImage()}
        width={'100%'}
        height={'100%'}
        sx={{
          objectFit: 'cover',
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            px: {
              xs: 2,
            },
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            gap: {
              xs: 2,
              md: 3,
            },
          }}>
          <Box>
            <Typography
              variant="body1"
              align="center"
              sx={{
                color: '#fff',
                fontSize: {
                  xs: 18,
                  sm: 50,
                  xl: 58,
                },
                fontWeight: 500,
              }}>
              DISCOVER INDONESIA YOUR WAY
            </Typography>

            <Typography
              variant="body1"
              align="center"
              sx={{
                color: '#fff',
                fontSize: {
                  xs: 18,
                  sm: 36,
                  xl: 40,
                },
                fontWeight: 500,
                mt: -0.5,
              }}>
              Where Every Journey is Personalized
            </Typography>
          </Box>

          <Grid container justifyContent="center">
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                gap: {
                  xs: 1,
                  md: 2,
                },
                '& .MuiFormControl-fullWidth': {
                  borderRadius: 1,
                },
              }}>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Search destinations, tours, activities"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                  opacity: 0.8,
                  '&:hover': {
                    opacity: 1,
                  },
                  '& .MuiOutlinedInput-input:focus': {
                    opacity: 1,
                  },
                  '& fieldset': {
                    border: 'none',
                  },
                }}
              />
              <Button variant="contained" color="primary" onClick={handleSearch}>
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default App;
